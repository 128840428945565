<template>
	<div v-loading="pageloading">
		<el-card>
			<div class="flexRow" style="justify-content: space-between;">
				<div style="font-size:16px;font-weight:bold">
					视频号直播接入
				</div>
				<el-link href="https://jusnn6k8al.feishu.cn/docs/doccn2NG0SCe56Z8IPdMdywpa5g" type="info"
					target="_blank" style="margin-top:10px">
					<i class="el-icon-document" style="margin-right:5px"></i>视频号直播接入说明
				</el-link>
			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="直播接入" name="1"></el-tab-pane>
				<el-tab-pane label="绑定与设置" name="2"></el-tab-pane>
			</el-tabs>
			<div style="width:60%;margin:30px 0px 0px 5%" v-if="activeName==1">
				<el-steps direction="vertical">
					<!-- 发布商城小程序 -->
					<el-step>
						<template slot="description">
							<el-card style="margin:0px 0px 20px 0px">
								<div class="flexRow" style="justify-content: space-between;">
									<div style="width:100%">
										<div class="stepTitle">发布商城小程序</div>
										<div v-if="state<=1" style="width:80%;margin-top:10px">
											授权绑定商城小程序，并发布上线。当前你的商城还未授权绑定微信小程序，请先前往授权绑定
											<div>
												目前仅部分类目可开通自定义版交易组件。
												<a href="https://jusnn6k8al.feishu.cn/docs/doccnqyzzBAXqvGF159U3LHa0pg"
													style="padding:0px;color:#409eff" target="_blank">
													点击查看准入类目</a>
											</div>
										</div>
										<div v-else-if="state==2" style="margin-top:10px">
											授权绑定商城小程序，并发布上线。当前绑定的商城小程序还未发布上线，请先前往发布
										</div>
										<div v-else-if="state>=3" style="margin-top:10px">
											授权绑定商城小程序，并发布上线。当前绑定的商城小程序：{{WxAppNickName}}
										</div>
									</div>
									<div class="flexColCenter">
										<el-button type="primary" size="mini" style="height:28px" v-if="state==2"
											@click="torelease">去发布</el-button>
										<el-button type="text" style="color:rgb(7, 193, 100);cursor:default"
											v-else-if="state>=3">已完成</el-button>
										<el-button type="primary" size="mini" style="height:28px" v-else
											@click="toauthorization">去授权</el-button>
									</div>
								</div>
							</el-card>
						</template>
					</el-step>
					<!-- 申请开通组件 -->
					<el-step>
						<template slot="description">
							<el-card style="margin:0px 0px 20px 0px">
								<div class="flexRow" style="justify-content:space-between;">
									<div style="width:100%">
										<div class="stepTitle">申请开通组件</div>
										<div style="width:80%;margin-top:10px">
											完成自定义版交易组件开通，小程序即可在视频号场景中实现商品展示和直播带货的功能，进一步提升商城经营能力。若你已开通标准版组件，请前往小程序后台取消开通。
											<span type="text" style="padding:0px;color:#409eff" v-if="state<4">
												<a href="https://jusnn6k8al.feishu.cn/docs/doccntRD6jBJ9Gb45gxq0Ay2xkh"
													style="color:#409eff" target="_blank">点击查看开通说明</a>
											</span>
										</div>
									</div>
									<div class="flexColCenter">
										<el-button type="text" style="color:rgb(7, 193, 100);cursor:default"
											v-if="state>=4">已完成</el-button>
										<el-button type="primary" size="mini" style="height:28px" v-else
											@click="toapplyfor">申请开通</el-button>
									</div>
								</div>
							</el-card>
						</template>
					</el-step>
					<!-- 商户号申请 -->
					<el-step>
						<template slot="description">
							<el-card style="margin:0px 0px 20px 0px">
								<div class="flexRow" style="justify-content:space-between;">
									<div style="width:100%">
										<div class="stepTitle">商户号申请</div>
										<div style="width:80%;margin-top:10px">
											视频号场景经营所需商户号的申请。商户号开通成功后，视频号订单产生的资金会进入该商户号。可与[上传交易组件商品]步骤同时进行。
											<br/>
											商家请前往小程序管理后台申请。
											<span type="text" style="padding:0px;color:#409eff">
												<a href="https://jusnn6k8al.feishu.cn/docs/doccnVn5QOpkF9cYXb7SYNJQazb"
													style="color:#409eff" target="_blank">点击查看申请说明</a>
											</span>
										</div>
										<div style="color:#F56C6C;margin-top: 10px;" v-if="muchIdApplyState===5 && audit_detail">
											失败原因：{{audit_detail}}
										</div>
									</div>
									<div class="flexColCenter">
										<el-button type="primary" size="mini" style="height:28px" v-if="muchIdApplyState===0" @click="handleToOpen">去开通</el-button>
										<div class="flexCenter" style="white-space: nowrap;font-size: 14px;" v-if="muchIdApplyState===1 || muchIdApplyState===2 || muchIdApplyState===3 ||muchIdApplyState===4">
											<div style="color: #409EEF;" v-if="muchIdApplyState===1">资料校验中</div>
											<div style="color: #409EEF;" v-if="muchIdApplyState===2">待账户验证</div>
											<div style="color: #409EEF;" v-if="muchIdApplyState===3">审核中</div>
											<div style="color: #409EEF;" v-if="muchIdApplyState===4">待签约</div>
											<el-tooltip effect="light" placement="right">
												<div slot="content">
													<span v-if="muchIdApplyState===1">刷新页面，获取校验证状态</span>
													<span v-if="muchIdApplyState===2">刷新页面，获取验证状态</span>
													<span v-if="muchIdApplyState===3">预计2个工作日内完成审核</span>
													<span v-if="muchIdApplyState===4">请前往小程序管理后台，签约开通协议</span>
												</div>
												<i class="el-icon-info" style="font-size: 16px;padding-left: 5px;color: #409EEF;"></i>
											</el-tooltip>
										</div>
										<div style="text-align: right;">
											<el-button type="text" style="color:#F56C6C;cursor:default" v-if="muchIdApplyState===5">申请驳回</el-button>
										</div>
										<el-button type="primary" size="mini" style="height:28px" v-if="muchIdApplyState===5" @click="handleToOpen">前往修改</el-button>
										<el-button type="text" style="color:rgb(7, 193, 100);cursor:default" v-else-if="muchIdApplyState===6">已完成</el-button>
									</div>
								</div>
							</el-card>
						</template>
					</el-step>
					<!-- 上传交易组件商品 -->
					<el-step>
						<template slot="description">
							<el-card style="margin:0px 0px 20px 0px">
								<div class="flexRow" style="justify-content: space-between;">
									<div style="width:100%">
										<div class="stepTitle">上传交易组件商品</div>
										<div style="width:80%;margin-top:10px;">添加商品并提交微信官方审核通过。
											<span type="text" style="padding:0px;color:#409eff">
												<a href="https://jusnn6k8al.feishu.cn/docs/doccnLXc1tN0XbPW4gWaLig099g"
													style="color:#409eff" target="_blank">点击查看添加说明</a>
											</span>
										</div>
										<div style="color:#F56C6C" v-if="state==6">
											失败原因：{{FailReason}}
										</div>
									</div>
									<div class="flexColCenter">
										<el-button type="text" style="color:rgb(7, 193, 100);cursor:default"
											v-if="state>=7">已完成</el-button>
										<el-button type="text" style="color:#409eff;cursor:default"
											v-else-if="state==5">审核中</el-button>
										<div v-else-if="state==6" class="flexColCenter">
											<el-button type="text" style="color:#F56C6C;cursor:default">审核失败</el-button>
											<el-button type="primary" size="mini" @click="toadd">重新提审</el-button>
										</div>
										<el-button type="primary" size="mini" style="height:28px" v-else @click="toadd">
											去添加</el-button>
									</div>
								</div>
							</el-card>
						</template>
					</el-step>
					<!-- 支付订单并同步 -->
					<el-step>
						<template slot="description">
							<el-card style="margin:0px 0px 20px 0px">
								<div class="flexRow" style="justify-content: space-between;">
									<div style="width:100%">
										<div class="stepTitle">支付订单并同步</div>
										<div style="width:80%;margin-top:10px"><span v-if="state<8">
												点击[去购买]，</span>在已经审核通过的商品中，选择任一商品购买并支付成功，等待订单同步。
											<span type="text" style="padding:0px;color:#409eff" v-if="state<8">
												<!-- <a href="https://jusnn6k8al.feishu.cn/docs/doccnLXc1tN0XbPW4gWaLig099g" target="_blank">点击查看添加说明</a> -->
											</span>
										</div>
									</div>
									<div class="flexColCenter">
										<el-button type="text" style="color:rgb(7, 193, 100);cursor:default"
											v-if="state>=8">已完成</el-button>
										<el-button type="primary" size="mini" style="height:28px" v-else @click="tobuy">
											去购买</el-button>
									</div>
								</div>
							</el-card>
						</template>
					</el-step>
					<!-- 申请小程序接入视频号带货场景 -->
					<el-step>
						<template slot="description">
							<el-card style="margin:0px 0px 20px 0px;width:90%">
								<div class="flexRow" style="justify-content: space-between;">
									<div style="width:100%">
										<div class="stepTitle">申请小程序接入视频号带货场景</div>
										<div style="width:80%;margin-top:10px">
											带货场景接入成功后，你可以：1.在视频号主页关联商城小程序内的商品；2.视频号直播间可以选择商城后台已经审核通过的商品；3.设置视频号推广员。
											<span type="text" style="padding:0px;color:#409eff">
												<a href="https://jusnn6k8al.feishu.cn/docs/doccnPQyYqRqYTq3yQ6HdX62rLg"
													style="color:#409eff" target="_blank">点击查看说明</a>
											</span>
										</div>
										<div style="color:#F56C6C" v-if="state==10">
											失败原因：{{FailReason}}
										</div>
									</div>
									<div class="flexColCenter">
										<el-button type="text" style="color:rgb(7, 193, 100);cursor:default"
											v-if="state>=11">接入成功</el-button>
										<el-button type="text" style="color:#409eff;cursor:default"
											v-else-if="state==9">审核中</el-button>
										<div class="flexColCenter" v-else-if="state==10">
											<el-button type="text" style="color:#F56C6C;cursor:default">审核失败</el-button>
											<el-button type="primary" size="mini" @click="videotoapply">重新提审</el-button>
										</div>
										<el-button type="primary" size="mini" style="height:28px" v-else
											@click="videotoapply">去申请</el-button>
									</div>
								</div>
							</el-card>
						</template>
					</el-step>
				</el-steps>
			</div>
			<div v-if="activeName==2">
				<!-- 直播状态完成 -->
				<div v-if="state==11" style="height:600px">
					<div style="margin-bottom:100px">
						<div class="flex">
							<div class="labelfont font-bold" style="margin-right:20px">视频号绑定</div>
							<div style="margin-top:3px">
								<div style="font-size:12px;font-weight: 400;color: #999999;margin-bottom:12px">
									成功绑定视频号ID，商城小程序中可展示视频号直播间，为视频号直播引流。视频号主体需要与商城小程序主体一致。
									<a href="https://jusnn6k8al.feishu.cn/docs/doccnkTHOZa6hbFWTvnwNkCwrSf" style="color:#409EEF"
										target="_blank">查看绑定说明</a>
								</div>
								<div>
									<div style="float:left;line-height:36px;font-size:14px;">视频号ID：</div>
									<el-input v-model="ruleForm.videoId" style="width:300px;height:36px;margin-right:20px"
										placeholder=""></el-input>
									<el-button type="primary" style="color:white;font-size:14px;width:96px;" @click="binding">确认绑定
									</el-button>
								</div>
								<div style="margin-top:10px">
									<a href="https://jusnn6k8al.feishu.cn/docs/doccnnlsL1iR8McS3lyfAg5fRid"
										style="font-size:12px;color: #409EEF;margin-left:68px;" target="_blank">如何获取视频号ID？</a>
								</div>
							</div>
						</div>
						<div style="margin-top:30px">
							<div class="labelfont font-bold" style="margin-right:20px">视频号设置</div>
							<div>
								<div class="flex" style="margin-top:20px">
									<div class="labelfont width-180-right">直播间立即购买链接：</div>
									<div>
										<el-radio v-model="videoform.DirectPath" :label="0">商品详情页</el-radio>
										<el-radio v-model="videoform.DirectPath" :label="1">直播凑单页</el-radio>
										<div style="color:#999999;font-size:12px;margin-top:7px">直播间点击购物袋→商品点击「购买」跳转的页面链接。
											<a href="https://jusnn6k8al.feishu.cn/docx/doxcnzlLHYfNZHBhSsvhUQLGasc"
											style="font-size:12px;color: #409EEF;" target="_blank">查看2种链接页面的区别</a>
										</div>
									</div>
								</div>
								<div class="flex" style="margin-top:20px">
									<div class="labelfont width-180-right">直播引流入口：</div>
									<div>
										<el-checkbox-group v-model="videoform.DrainageEntrance">
											<el-checkbox :label="1">商品详情页</el-checkbox>
											<el-checkbox :label="2">多人拼团活动页
												<el-popover placement="top" width="200" trigger="hover" content="多人拼团活动页包含：好友参团页面、拼团结果页面">
													<i class="el-icon-warning" style="color:#C0C4CC" slot="reference"></i>
												</el-popover>
											</el-checkbox>
										</el-checkbox-group>
										<div style="color:#999999;font-size:12px;margin-top:7px">1.直播间预告、进入直播间的入口展示的页面
											<el-popover placement="top" trigger="click">
												<div class="flex" style="width:425px">
													<div style="margin-right:65px">
														<div style="color:#999999;font-size:12px;margin-bottom:10px">商品详情页</div>
														<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/liveCutIn-img2.png" style="width:180px" alt="">
													</div>
													<div>
														<div style="color:#999999;font-size:12px;margin-bottom:10px">多人拼团活动页</div>
														<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/liveCutIn-img1.png" style="width:180px" alt="">
													</div>
												</div>
												<el-button type="text" style="margin:0px;padding:0px;font-size:12px" slot="reference">查看示例</el-button>
											</el-popover>
										</div>
										<div style="color:#999999;font-size:12px;margin-top:7px">2.你还可以通过商城装修，自定义视频号直播引流入口展示。
											<a href="https://jusnn6k8al.feishu.cn/docx/doxcnuinPhhqyPsClCa0Wzj9dAe"
											style="font-size:12px;color: #409EEF;" target="_blank">查看模块装修说明</a>
										</div>
										<div style="color:#999999;font-size:12px;margin-top:7px">3.已绑定的视频号ID主体与商城小程序主体不一致，入口无法显示</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bottom-box">
						<!-- <el-button type="primary">取消</el-button> -->
						<el-button type="primary" @click="savevideoConfig">保存</el-button>
					</div>
				</div>
				<!-- 直播状态未完成 -->
				<div v-else>
					<p
						style="font-size:14px;color:#606266;flot:left;font-weight: 500;margin-bottom:6px;margin-top:11px">
						视频号绑定</p>
					<p style="font-size:12px;font-weight: 400;color: #999999;">
						成功绑定视频号ID，商城小程序中可展示视频号直播间，为视频号直播引流。视频号主体需要与商城小程序主体一致。
						<a href="https://jusnn6k8al.feishu.cn/docs/doccnkTHOZa6hbFWTvnwNkCwrSf" style="color:#409EEF"
							target="_blank">查看绑定说明</a>
					</p>
					<div class="cardFlex">
						<div class="flexCenter">
							<img src="https://cdn.dkycn.cn/images/melyshop/menuIcon/xiaoshouqudao/shipinhaozhibo.png"
								style="width:30px;height:30px" alt="">
							<span class="grayFont" style="margin-left:10px;color: #909399;font-size:16px;">视频号直播</span>
							<i class="el-icon-close grayFont"
								style="margin:0px 15px;font-size:30px; color: #606266;"></i>
							<img src="https://cdn.dkycn.cn/melyshop/image/20210730-b62b0dbb-c383-4d16-9e9f-af292aa8bc71.png"
								style="width:30px;height:30px; color: #606266;" alt="">
							<span class="grayFont" style="margin-left:10px; color: #909399;font-size:16px">企店</span>
						</div>
						<div class=" flexCenter grayFont"
							style="font-size:14px;margin:40px auto 5px auto;color: #909399;">
							商城还未接入自定义版交易组件，请先完成接入
						</div>
						<div class="flexCenter">
							<el-button type="text" @click="gocutin">
								<span style="color:#409EFF">去接入</span>
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</el-card>
		<!-- 购买商品并同步订单 -->
		<!-- <el-dialog title="购买商品并同步订单" :visible.sync="buySynchronous" v-loading="buySynchronousloading"
			@close="ordernum = ''">
			<div class="theFont">请前往商品橱窗列表，在列表中选择任一
				<span style="color:#F56C6C">
					审核通过
				</span>
				的商品购买；支付成功后，在下方输入订单编号，点击[完成购买]，等待订单同步
			</div>
			<el-button type="text" style="color:#409EFF" @click="toshopwindow">前往商品橱窗购买>>></el-button>
			<div class="flexRow" style="margin-top:20px">
				<el-input v-model="ordernum" placeholder="请填写订单编号"></el-input>
				<el-button type="primary" style="margin-left:10px;color:white" @click="finishbuy">完成购买</el-button>
			</div>
		</el-dialog> -->
		<el-dialog title="购买商品并同步订单" :visible.sync="buySynchronous" v-loading="buySynchronousloading" width="660px">
			<div class="buy-tips">1.使用微信，扫描下方小程序码，进入商品橱窗列表页，选择任一商品购买。</div>
			<div class="buy-qrcode">
				<img :src="qrCode">
			</div>
			<div class="buy-tips-1">
				<div class="left" style="color: #E6A23C;">提示:</div>
				<div class="right">
					<div style="color: #E6A23C;">1.该页面仅用于完成视频号订单接口调用，请勿分享给客户或投放至其他渠道;</div>
					<div style="color: #E6A23C;">2.订单支付成功后，系统将根据微信接口要求，自动完成发货、确认收货、退款等不同状态的同步，商家不需要对订单进行任何操作</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		videolivevideoliveapplyinfo,
		videolivevideoliveapply,
		videosyncvideoliveorderinfo,
		videolivevideoliveapplyscene,
		saveVideoLiveId,
		videoLivevideoLiveConfigSave,
		videoLivevideoLiveConfig
	} from "@/api/sv1.0.0"
	import {
		mallFreeVersionConfigInfo
	} from '@/api/goods.js';

	import apiList from '@/api/other';
	import config from '@/config/index';
	
	export default {
		data() {
			return {
				imgUrl: config.IMG_BASE,
				goUrls: config.GO_URL,
				ruleForm: {
					videoId: '',
				},
				activeName: '1',
				pageloading: false,
				// 未授权小程序 = 1,
				// 未发布小程序 = 2,
				// 未申请开通组件 = 3,
				// 未上传商品 = 4,
				// 商品审核中 = 5,
				// 商品审核失败 = 6,
				// 支付订单未同步 = 7,
				// 场景未开通 = 8,
				// 场景审核中 = 9,
				// 场景审核失败 = 10,
				// 组件已开通 = 11
				state: 0,
				VideoLiveId: '',
				FailReason: '',
				WxAppNickName: '',
				data: {},
				buySynchronous: false,
				ordernum: '',
				buySynchronousloading: false,
				
				muchIdApplyState: '',
				audit_detail: '',
				qrCode: '',
				videoform:{
					DirectPath:0,
					DrainageEntrance:[]
				}
			}
		},
		created() {
			this.activeName = this.$route.query.activeName || '1';
			this.getvideolivevideoliveapplyinfo()
			this.getData()
			if(this.$route.params.tab){
				this.$nextTick(()=>{
					this.activeName = String(this.$route.params.tab)
				})
			}
			// this.buySynchronous = true;
			// if (!this.qrCode){
			// 	this.getMiniProgramCode();
			// }
		},
		methods: {
			async savevideoConfig(){
				try{
					let res = await videoLivevideoLiveConfigSave(this.videoform)
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.getvideoform()
					}
				}finally{
					//
				}
			},
			async getvideoform(){
				try{
					let res = await videoLivevideoLiveConfig()
					if(res.IsSuccess){
						this.videoform = res.Result
					}
				}finally{
					//
				}
			},
			handleClick(tab, event) {
				if (tab.index == 1) {
					this.getvideolivevideoliveapplyinfo()
					this.ruleForm.videoId = this.VideoLiveId
					this.getvideoform()
				} else if (tab.index == 0) {
					this.getvideolivevideoliveapplyinfo()
				}
			},
			//视频号绑定
			gocutin() {
				this.activeName = Number(this.activeName) - 1 + ""
			},
			binding() {
				if (this.ruleForm.videoId == "") {
					this.$message.error({
						message: "请输入视频号ID"
					})
				} else {
					this.saveVideoLiveId()
				}
			},
			//视频号绑定保存
			async saveVideoLiveId() {
				try {
					let data = {
						VideoLiveId: this.ruleForm.videoId
					}
					let result = await saveVideoLiveId(data)
					if (result.IsSuccess) {
						this.$message.success({
							message: "视频号ID绑定成功"
						})
					}
				} finally {}
			},
			//视频号去申请
			videotoapply() {
				if (this.state < 8) {
					this.$message.error('请先完成订单同步')
				} else {
					this.tovideolivevideoliveapplyscene()
				}
			},
			async tovideolivevideoliveapplyscene() {
				this.pageloading = true
				try {
					let result = await videolivevideoliveapplyscene()
					if (result.IsSuccess) {
						this.$message.success('操作成功')
					}
				} finally {
					this.pageloading = false
					this.getvideolivevideoliveapplyinfo()
				}
			},
			//完成购买
			finishbuy() {
				if (!this.ordernum) {
					this.$message.error('请填写支付成功的订单编号')
				} else {
					//调接口
					this.tovideosyncvideoliveorderinfo()
				}
			},
			async tovideosyncvideoliveorderinfo() {
				this.buySynchronousloading = true
				try {
					let data = {
						OrderNo: this.ordernum
					}
					let result = await videosyncvideoliveorderinfo(data)
					if (result.IsSuccess) {
						// console.log(result)
						this.$message.success('同步成功')
					}
				} finally {
					this.buySynchronousloading = false
					this.buySynchronous = false
					this.ordernum = ''
					this.getvideolivevideoliveapplyinfo()
				}
			},
			//前往商品橱窗
			toshopwindow() {
				this.$router.push({
					path: '/setting/liveVideo/shopwindow'
				})
			},
			//去购买
			tobuy() {
				if (this.muchIdApplyState !== 6){
					this.$message.error('请先完成商户号申请')
				}else if (this.state < 7) {
					this.$message.error('请先上传交易组件商品')
				} else{
					this.buySynchronous = true;
					if (!this.qrCode){
						this.getMiniProgramCode();
					}
				}
			},
			//去添加
			toadd() {
				if (this.state < 4) {
					this.$message.error('请先开通自定义版交易组件')
				} else {
					this.$router.push({
						path: '/setting/liveVideo/shopwindow'
					})
				}
			},
			//去申请
			toapplyfor() {
				if (this.state < 3) {
					this.$message.error('请先绑定商城小程序并发布上线')
				} else {
					this.tovideolivevideoliveapply()
				}
			},
			//开通组件
			async tovideolivevideoliveapply() {
				
				this.pageloading = true
				try {
					let result = await videolivevideoliveapply({
						noError: true
					})
					if (result.IsSuccess) {
						
						const h = this.$createElement;
						this.$msgbox({
							title: '交易组件开通确认',
							message: h('p', null, [
								h('span',
									'微信已向小程序管理员发送交易组件开通确认通知，请联系管理员点击通知并确认开通，通知24小时内有效。'
									),
								h('a', {
									style: 'color: #409EEF',
									attrs: {
										href: 'https://jusnn6k8al.feishu.cn/docs/doccn2NG0SCe56Z8IPdMdywpa5g',
										target: '_blank'
									}
								}, '点击查看操作说明')
							]),
							showCancelButton: true,
							confirmButtonText: '管理员已确认开通',
							cancelButtonText: '取消'
						}).then(() => {
							this.getvideolivevideoliveapplyinfo('valid');
						}).catch(() => {})
						return;
						
						// this.$message.success('操作成功')
						//61007权限集未授权
						//1040005类目不准接入
					} else {
						// result.ErrorCode = 1000000
						if (result.ErrorCode == 61007) {
							const h = this.$createElement;
							this.$msgbox({
								title: '提示',
								message: h('p', null, [
									h('span',
										'已授权的商城小程序，缺少[自定义版交易组件]权限，请前往[销售渠道]—[微信小程序]—[商城小程序]，点击重新授权，将 '
										),
									h('span', {
										style: 'color: #F56C6C'
									}, '[自定义版交易组件]'),
									h('span', '权限授权给企店'),
								]),
								showCancelButton: true,
								confirmButtonText: '前往重新授权',
								cancelButtonText: '关闭',
								type: 'warning'
							}).then(() => {
								this.$router.push({
									path: '/setting/wxAppManage'
								})
							}).catch(() => {})
						} else if (result.ErrorCode == 1040005) {
							const h = this.$createElement;
							this.$msgbox({
								title: '提示',
								message: h('div', null, [
									h('span', {
										style: 'line-height:20px'
									}, `小程序当前服务类目暂不允许接入自定义版交易组件，请登录小程序：`),
									h('span', {
										style: 'font-weight: bold'
									}, this.WxAppNickName),
									h('span', {
										style: 'line-height:20px'
									}, '，添加服务类目。'),
									h('br'),
									h('a', {
										attrs: {
											href: 'https://jusnn6k8al.feishu.cn/docs/doccnqyzzBAXqvGF159U3LHa0pg',
											target: '_blank'
										},
										style: 'color:#409EFF;line-height:20px'
									}, '>>>查看准入类目'),
									h('br'),
									h('a', {
										attrs: {
											href: 'https://jusnn6k8al.feishu.cn/docs/doccn9AGced3zVWOLut2Ow2CYug',
											target: '_blank'
										},
										style: 'color:#409EFF;line-height:20px'
									}, '>>>如何添加服务类目？'),
								]),
								showCancelButton: true,
								dangerouslyUseHTMLString: true,
								confirmButtonText: '登录小程序',
								cancelButtonText: '关闭',
								type: 'warning'
							}).then(() => {
								window.open('https://mp.weixin.qq.com/')
							}).catch(() => {})
						} else {
							this.$message.error(result.Message)
						}
						// console.log(result,987)
					}
				} finally {
					this.pageloading = false
					this.getvideolivevideoliveapplyinfo()
				}
			},
			//去授权
			toauthorization() {
				window.open(this.data.WxAppAuthUrl)
			},
			//去发布
			torelease() {
				this.$router.push({
					path: '/setting/wxAppManage'
				})
			},
			async getData() {
				let result = await mallFreeVersionConfigInfo()
				// console.log(result.Result)
				this.data = result.Result;
			},
			//获取流程进度
			async getvideolivevideoliveapplyinfo(type) {
				this.pageloading = true
				let result = await videolivevideoliveapplyinfo()
				if (result.IsSuccess) {
					// console.log(result.Result)
					this.state = result.Result.State
					this.FailReason = result.Result.FailReason
					this.WxAppNickName = result.Result.WxAppNickName
					this.ruleForm.videoId = result.Result.VideoLiveId;
					this.muchIdApplyState = result.Result.MuchIdApplyState;
					if (result.Result.audit_detail && result.Result.audit_detail.length){
						result.Result.audit_detail.map(item=>{
							this.audit_detail += '错误资料:' + item.param_name + '，错误原因:' + item.reject_reason+'；';
						})
					}
					
					if (type === 'valid'){
						if (this.state <= 3){
							this.$message.error('开通失败或管理员未确认，请重新申请开通');
						}
					}
				}
				this.pageloading = false
			},
			
			handleToOpen(){
				if (this.state < 4) {
					this.$message.error('请先开通自定义版交易组件')
				} else {
					window.open('https://mp.weixin.qq.com/')
				}
			},
			
			/**
			 * 获取小程序码
			 */
			async getMiniProgramCode() {
				let params = {
					Page: 'pageA/pages/videoAccount/videoAccount',
					Scene: 'id=1',
					IsLimited: false,
					ProgramType: 0,
					noError: true
				}
				
				this.buySynchronousloading = true;
				
				let res = await apiList.mallGetQrCode(params);
				
				this.buySynchronousloading = false;
				
				if (res.ErrorCode === 0) {
					this.qrCode = this.imgUrl + JSON.parse(res.Result)[0];
				} else {
					this.showDialog = false;
					if (res.ErrorCode == 1000063) { //未绑定商城小程序
						this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
							this.signLoding = false;
							this.state = 0;// window.localStorage.getItem('mlmzFreePayState');
							if (this.state == 0) {
								this.$confirm('请先绑定商城小程序', '提示', {
									distinguishCancelAndClose: true,
									confirmButtonText: '去绑定',
									cancelButtonText: '取消'
								}).then(() => {
									this.getMallConfig();
								}).catch(action => {
								})
								return
							}
							window.localStorage.setItem('canEditSetinfos', true);
							window.localStorage.setItem('toPayResultFromPath', this.$route.path);
						}).catch(() => {})
					} else if (res.ErrorCode == 85074) { //小程序未发布
						this.$confirm('商城小程序还未发布上线，小程序生成失败', '', {
							confirmButtonText: '去发布',
							cancelButtonText: '取消',
						}).then(() => {
							let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
							let url = this.goUrls + headsUrls + '/setting/wxAppManage'
							window.open(url);
						}).catch(() => {
						})
					} else if (res.ErrorCode == 41030) { //page页面不存在
						this.$confirm('页面小程序码生成失败，请先将小程序版本更新成最新版本', '', {
							confirmButtonText: '去更新',
							cancelButtonText: '取消',
						}).then(() => {
							// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
							// let url = this.goUrls + headsUrls + '/setting/wxAppManage'
							// window.open(url);
							this.$router.push({
								name: 'wxAppManage'
							})
						}).catch(() => {
						})
					}
				}
			},
			async getMallConfig() {
				let res = await mallFreeVersionConfigInfo()
				this.mallConfigInfo = res.Result;
				window.open(this.mallConfigInfo.WxAppAuthUrl);
				this.handleClose();
			},
		}
	}
</script>
<style scoped>
	*,
	html,
	body p div {
		/* color: #606266; */
	}
</style>
<style lang="less" scoped>
.bottom-box{
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 100px;
	// border:1px solid black;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99;
}
.flex{
	display: flex;
}
.labelfont{
	font-size: 14px;
	color: #606266;
	white-space: nowrap;
}
.font-bold{
	font-weight: bold;
}
.width-180-right{
	width: 180px;
	text-align: right;
	white-space: nowrap;
	margin-right: 10px;
}
	.flexRow {
		display: flex;
		flex-direction: row;
	}

	.finishFont {
		color: rgb(7, 193, 100);
	}

	.flexColCenter {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.flexColCenter>.el-button {
		width: 90px;
		height: 36px !important;
		font-size: 14px;
		color: white;
	}

	.theFont {
		color: #909399;
		line-height: 24px;
		font-size: 14px
	}

	.stepTitle {
		font-size: 14px;
		font-weight: bold;
		color: black
	}

	.cardFlex {
		width: 100%;
		height: 550px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.flexCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.buy-tips{
		font-size: 14px;
		color: #606266;
	}
	.buy-qrcode{
		margin: 30px auto;
		width: 160px;
		height: 160px;
		
		img{
			display: block;
			width: 100%;
		}
	}
	.buy-tips-1{
		font-size: 14px;
		line-height: 22px;
		display: flex;
		.left{
			flex: 0 0 auto;
			white-space: nowrap;
			margin-right: 5px;
		}
		.right{
			flex: 1 1 auto;
		}
	}
</style>
